import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 300.000000 300.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,300.000000) scale(0.100000,-0.100000)">


<path d="M664 2860 c-232 -34 -424 -197 -502 -424 l-27 -81 -3 -824 c-3 -929
-3 -923 67 -1066 71 -145 197 -256 353 -313 l73 -27 847 -3 c944 -3 931 -4
1066 64 141 71 254 203 309 359 l28 80 0 865 0 865 -22 70 c-74 233 -270 400
-510 435 -79 11 -1601 11 -1679 0z m1109 -247 c50 -14 100 -27 110 -30 10 -3
17 -15 17 -29 0 -28 0 -28 -64 -9 -80 24 -230 45 -326 45 -72 0 -222 -16 -302
-33 -7 -1 -14 7 -16 19 -3 18 6 24 60 39 149 41 365 41 521 -2z m105 -418 c89
-21 157 -59 228 -127 230 -220 197 -591 -67 -767 -91 -61 -167 -81 -309 -81
l-118 0 -6 -27 c-3 -16 -12 -68 -21 -118 -36 -205 -66 -278 -143 -346 -75 -65
-143 -92 -247 -96 -49 -2 -96 0 -103 5 -11 8 -9 33 12 143 14 74 48 250 75
391 27 141 58 277 69 301 28 63 90 120 154 142 42 14 97 19 261 23 207 4 208
5 227 30 25 31 25 63 1 93 l-19 24 -354 5 -353 5 -67 32 c-127 60 -215 184
-226 319 l-5 64 474 0 c383 0 486 -3 537 -15z m20 -1771 c-4 -19 -86 -49 -191
-69 -143 -27 -387 -15 -490 24 -17 7 -27 18 -27 31 0 24 0 24 92 4 147 -32
461 -14 590 35 24 9 32 2 26 -25z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>
}
